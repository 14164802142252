

































































































import Logger from '../services/logger'
import Vue from 'vue'
import Component from 'vue-class-component'
// @ts-ignore
import AceEditor from '../components/AceEditor.vue'

enum OutboundEventId {
  CodeSyncRequestResponse = 'CODE:SYNC-REQUEST:RESPONSE'
}

enum EventId {
  State = 'STATE',
  CodeSyncRequest = 'CODE:SYNC-REQUEST',
  CodeSyncRequestResponse = 'CODE:SYNC-REQUEST:RESPONSE',
  UserList = 'USER:LIST',
  CodeRoomJoinSuccess = 'CODEROOM:JOIN:SUCCESS',
  UserJoined = 'USER:JOINED',
  RunCode = 'CODE:RUN',
  CodeRan = 'CODE:RAN',
  CodeChanged = 'CODE:CHANGED',
  CursorMoved = 'CURSOR:MOVED'
}

interface WebsocketEvent {
  type: EventId
}

interface User {
  colour: string
  name: string
  id: string
}

interface StateEvent extends WebsocketEvent {
  type: EventId.State
  users: {[key: string]: User}
}

interface CodeSyncRequest extends WebsocketEvent {
  type: EventId.CodeSyncRequest
  clientId: string
}

interface CodeSyncResponse extends WebsocketEvent{
  type: EventId.CodeSyncRequestResponse
  clientId: string
  code: string
}

interface CodeRoomJoinSuccess extends WebsocketEvent {
  type: EventId.CodeRoomJoinSuccess
  id: string
  code: string
}

interface UserJoinedEvent extends WebsocketEvent {
  type: EventId.UserJoined
  name: string
}

interface TestRunnerOutput {
  logType: 'error' | 'output'
  output: string
  date: number
  name: string
}
interface CodeRanEvent extends WebsocketEvent, TestRunnerOutput {
  type: EventId.CodeRan
}
interface CodeChangeEvent extends WebsocketEvent {
  type: EventId.CodeChanged
  timestamp: number
  clientId: string
  content: unknown // fix
}
interface CursorMovedEvent extends WebsocketEvent {
  type: EventId.CursorMoved
  position: unknown
  clientId: string
}

type WebSocketEvents =
  StateEvent
  | CodeSyncRequest
  | CodeSyncResponse
  | CodeRoomJoinSuccess
  | UserJoinedEvent
  | CodeRanEvent
  | CodeChangeEvent
  | CursorMovedEvent

interface TestScenario {
  message: string
  tests: {
    input: string
    output: number
  }[]
}

@Component({
  components: {
    AceEditor
  }
})
export default class Home extends Vue {
  websocket: WebSocket | undefined
  wsOpen: boolean = false
  clientId: string = ''
  id: string = ''
  code: string = ''
  name: string = ''
  nameSet: boolean = false
  roomJoined: boolean = false
  lastEdit: number = 0
  users: {[key: string]: User} = {}

  editingScenarios: boolean = false
  scenarioInput: string = ''
  scenarios: TestScenario[] = []

  syncUser: User = {
    colour: '',
    name: '',
    id: ''
  }

  requestingCodeFromClient: boolean = false
  testRunnerOutput: TestRunnerOutput[] = [{
    logType: 'output',
    output: 'Click run to run the code.',
    date: Date.now(),
    name: 'system'
  }]

  editScenarios () {
    this.editingScenarios = true
  }

  saveScenarios () {
    this.scenarios = JSON.parse(this.scenarioInput)
    this.editingScenarios = false
  }

  cursorChangedFn (position: {
    row: number
    column: number
  }) {
    this.emitEvent(EventId.CursorMoved, {
      position
    })
  }

  updateState (event: StateEvent) {
    this.users = event.users
  }

  emitEvent (event: EventId, data: {[key: string]: any}) {
    if (this.wsOpen && this.websocket !== undefined) {
      this.websocket.send(JSON.stringify({
        type: event,
        ...data
      }))
    }
  }

  clearConsole () {
    this.testRunnerOutput = [{
      logType: 'output',
      output: 'Click run to run the code.',
      date: Date.now(),
      name: 'system'
    }]
  }

  connectToRoom () {
    if (this.name.length > 10) {
      if (this.nameSet) {
        this.name = ''
        this.nameSet = false
        return
      }
      return this.$bvToast.toast('Maximum name length is 10', {
        title: 'Validation Error',
        variant: 'danger',
        autoHideDelay: 2000,
        appendToast: false
      })
    }
    if (this.name.length < 1) {
      if (this.nameSet) {
        this.name = ''
        this.nameSet = false
        return
      }
      return this.$bvToast.toast('Minimum name length is 1', {
        title: 'Validation Error',
        variant: 'danger',
        autoHideDelay: 2000,
        appendToast: false
      })
    }
    if (!this.nameSet) {
      this.$router.push({
        name: 'Home',
        params: {
          id: this.id,
          name: this.name
        }
      })
    }
    this.websocket = new WebSocket(`wss://code.scotsoo.me/websocket?roomid=${this.id}&name=${this.name}`)
    this.websocket.addEventListener('open', () => {
      this.wsOpen = true
    })
    this.websocket.addEventListener('close', () => {
      this.wsOpen = false
    })
    this.websocket.addEventListener('message', ({
      data
    }: {
      data: string
    }) => {
      const event: WebSocketEvents = JSON.parse(data)
      switch (event.type) {
        case EventId.State:
          this.updateState(event)
          break
        case EventId.CodeSyncRequest:
          this.emitEvent(EventId.CodeSyncRequestResponse, {
            code: this.code,
            clientId: event.clientId
          })
          break
        case EventId.CodeSyncRequestResponse:
          this.code = event.code
          // @ts-ignore
          this.$refs.editor.fullCodeUpdatedExternally(this.code)
          if (this.requestingCodeFromClient) {
            // @ts-ignore
            this.$refs.editor.setReadonly(false)
            this.requestingCodeFromClient = false
          }
          this.lastEdit = Date.now()
          break
        case EventId.CodeRoomJoinSuccess:
          this.roomJoined = true
          this.clientId = event.id
          this.code = event.code
          setTimeout(() => {
            // @ts-ignore
            this.$refs.editor.fullCodeUpdatedExternally(this.code)
            console.log('code', event.code)
          }, 100)

          this.emitEvent(EventId.CodeSyncRequest, {
            // clientId: this.clientId
          })
          this.$bvToast.toast(`Successfully connected to room ${this.id}`, {
            title: 'Connected',
            variant: 'success',
            autoHideDelay: 2000,
            appendToast: false
          })
          break
        case EventId.UserJoined:
          this.$bvToast.toast(`User ${event.name} connected to room ${this.id}`, {
            title: 'User Connected',
            variant: 'success',
            autoHideDelay: 2000,
            appendToast: false
          })
          break
        case EventId.CodeRan:
          this.testRunnerOutput.push(event)
          // debounce slightly giving vue time to update properly
          setTimeout(() => {
            // eslint-disable-next-line no-case-declarations
            const element = document.getElementById('scroll-box')
            if (element !== null && element !== undefined) {
              element.scrollTop = element.scrollHeight
            }
          }, 50)
          break
        case EventId.CodeChanged:
          Logger.debug(EventId.CodeChanged, {
            ...event, lastEdit: this.lastEdit
          })
          if (event.timestamp < this.lastEdit) {
            this.syncUser = this.users[event.clientId]
            console.log('showing user')
            this.$bvToast.show('sync-toast')
            // @ts-ignore
            this.$refs.editor.setReadonly(true)
            this.requestingCodeFromClient = true
            console.log('requesting code sync from client', event.clientId)
            this.emitEvent(EventId.CodeSyncRequest, {
              clientId: event.clientId
            })
            return
          }
          this.lastEdit = event.timestamp
          if (event.clientId === this.clientId) {
            return
          }
          // @ts-ignore
          this.$refs.editor.codeUpdatedExternally(event.content)
          break
        case EventId.CursorMoved:
          // @ts-ignore
          this.$refs.editor.addMarker(event.clientId, {
            position: event.position,
            clientId: event.clientId,
            name: this.users[event.clientId].name,
            colour: this.users[event.clientId].colour
          })
          break
            // this.$socket.client.on('USER-CURSOR-POSITION-CHANGED', (arg: {
    //   position: {
    //     row: number
    //     column: number
    //   }
    //   clientId: string
    //   name: string
    //   colour: string
    // }) => {
    //   this.$refs.editor.addMarker(arg.clientId, arg)
    // })
      }
    })

    // this.$socket.client.on('ROOM-USERS', ({
    //   users
    // }: {
    //   users: {[key: string]: User}
    // }) => {
    //   this.users = users
    // })
    // this.$socket.client.on('USER-CURSOR-POSITION-CHANGED', (arg: {
    //   position: {
    //     row: number
    //     column: number
    //   }
    //   clientId: string
    //   name: string
    //   colour: string
    // }) => {
    //   this.$refs.editor.addMarker(arg.clientId, arg)
    // })

    // this.$socket.client.on('CODE-CHANGED', ({
    //   content,
    //   clientId,
    //   timestamp
    // }: {
    //   content: string
    //   clientId: string
    //   timestamp: number
    // }) => {
    //   Logger.debug('CODE-CHANGED', {
    //     content, clientId, timestamp, lastEdit: this.lastEdit
    //   })
    //   if (timestamp < this.lastEdit) {
    //     this.syncUser = this.users[clientId]
    //     console.log('showing user')
    //     this.$bvToast.show('sync-toast')
    //     this.$refs.editor.setReadonly(true)
    //     this.requestingCodeFromClient = true
    //     console.log('requesting code sync from client', clientId)
    //     this.$socket.client.emit('REQUEST-CODE-SYNC', {
    //       clientId
    //     })
    //     return
    //   }
    //   this.lastEdit = timestamp
    //   if (clientId === this.$socket.client.id) {
    //     return
    //   }
    //   // @ts-ignore
    //   this.$refs.editor.codeUpdatedExternally(content)
    // })
    // this.nameSet = true
    // this.$socket.client.emit('JOIN-ROOM', {
    //   id: this.id,
    //   name: this.name
    // })
    // Logger.debug('EMITTED JOIN-ROOM')
  }

  run () {
    this.emitEvent(EventId.RunCode, {
      code: this.code
    })
    // this.$socket.client.emit('RUN-CODE', {
    //   code: this.code,
    //   id: this.id
    // })
    Logger.debug('EMITTED RUN-CODE')
  }

  mounted () {
    this.id = this.$route.params.id
    this.name = this.$route.params.name
    if (this.name !== undefined) {
      this.nameSet = true
      this.connectToRoom()
    }
  }

  codeUpdatedFn (code: string) {
    this.code = code
  }

  contentUpdatedHandler (content: string) {
    const n = Date.now()
    this.lastEdit = n
    const args = {
      content,
      id: this.id,
      timestamp: n
    }
    this.emitEvent(EventId.CodeChanged, args)
    // this.$socket.client.emit('CODE-UPDATED', args)
    Logger.debug('EMITTED CODE-UPDATED', args)
  }
}
