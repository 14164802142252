// @ts-ignore
import { inspect } from 'util'
enum LogLevel {
  DEBUG = '1',
  INFO = '2',
  ERROR = '3'
}
const logLevelToString = {
  [LogLevel.DEBUG]: 'DEBUG',
  [LogLevel.INFO]: 'INFO',
  [LogLevel.ERROR]: 'ERROR'
}
export default class Logger {
  private static logLevel: LogLevel = LogLevel.INFO

  public static debug (message: string, ...args: unknown[]): void {
    this.log(LogLevel.DEBUG, message, args)
  }

  public static info (message: string, ...args: unknown[]): void {
    this.log(LogLevel.INFO, message, args)
  }

  public static error (message: string, ...args: unknown[]): void {
    this.log(LogLevel.ERROR, message, args)
  }

  private static log (logLevel: LogLevel, message: string, ...args: unknown[]): void {
    if (logLevel >= this.logLevel) {
      // @ts-ignore
      console.log(`[${new Date().toISOString()}] ${logLevelToString[logLevel]}: ${message}`, this.formatLogString(...args))
    }
  }

  protected static formatLogString (...args: unknown[]): string {
    return args
      .map(arg => {
        if (arg instanceof Error) return arg.stack
        if (typeof arg === 'string') return arg
        if (typeof arg === 'object') {
          try {
            return JSON.stringify(arg, null, 2)
          } catch (err) { }
          try {
            return inspect(arg)
          } catch (err) { }
        }
        // @ts-ignore
        return arg.toString()
      })
      .join(' ')
  }
}
