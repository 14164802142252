
























































import Component from 'vue-class-component'
// @ts-ignore
import AceEditor from 'vuejs-ace-editor'
import Vue from 'vue'
// @ts-ignore
const Range = window.ace.acequire('ace/range').Range

interface ChangeDelta {
  action: 'insert' | 'remove'
  end: {
    column: number
    row: number
  }
  lines: string[]
  start: {
    column: number
    row: number
  }
}
interface UserMarker {
  position: {
    row: number
    column: number
  }
  markerId: string
  name: string
  colour: string
  show: boolean
  marker: {
    update: () => unknown
  }
}
@Component({
  components: {
    AceEditor
  }
})
export default class AceEditorClass extends Vue {
  code: string = ''
  editorId: string = 'abc'
  editor: unknown = AceEditor

  markers: { [key: string]: UserMarker } = {}

  addMarker (id: string, marker: UserMarker) {
    if (this.markers[id] !== undefined) {
      // @ts-ignore
      this.editor.session.removeMarker(this.markers[id].markerId)
    }
    this.markers[id] = marker
    // @ts-ignore
    const myCurrentPos = this.editor.getCursorPosition()
    if (
      this.markers[id].position.row === myCurrentPos.row &&
      this.markers[id].position.column === myCurrentPos.column
    ) {
      this.markers[id].show = false
      return
    }
    this.markers[id].show = true
    // @ts-ignore
    const newMarker = this.editor.session.addDynamicMarker(this.getMarker(id))
    this.markers[id].markerId = newMarker.id
    // timer so we don't re-render markers that are timing out
    setTimeout(() => {
      this.markers[id].show = false
    }, 300)
  }

  getMarker (id: string) {
    const marker = {
      update: (html: string[], markerLayer: {
        $getTop: (number: number, config: unknown) => number
        $padding: number
      }, session: {
        documentToScreenPosition: (number: {
          row: number,
          column: number
        }) => {
          row: number
          column: number
        }
      }, config: {
        lineHeight: number
        characterWidth: number
      }) => {
        const user = this.markers[id]
        if (user.show) {
          const pos = this.markers[id].position
          const name = this.markers[id].name
          const screenPos = session.documentToScreenPosition(pos)
          const height = config.lineHeight
          const width = config.characterWidth
          const top = markerLayer.$getTop(screenPos.row, config)
          const left = markerLayer.$padding + screenPos.column * width
          html.push(`<div class="my-cursor" style="left:${left}px;height:${height}px;top:${top}px;width:${width}px;border-color:${user.colour};">
            <span class="cursor-name" style="background-color:${user.colour};">${name}</span>
          </div>`)
        }
      }
    }
    // @ts-ignore
    marker.redraw = function () {
      // @ts-ignore
      this.session._signal('changeFrontMarker')
    }
    // @ts-ignore
    marker.addCursor = function () {
      // @ts-ignore
      marker.redraw()
    }
    return marker
  }

  mounted () {
    // @ts-ignore
    this.editor = window.ace.edit(this.editorId)
    // @ts-ignore
    this.editor.on('change', (c: ChangeDelta) => {
      // @ts-ignore
      this.$emit('updated-code', this.editor.getValue())
      // @ts-ignore
      if (this.editor.curOp && this.editor.curOp.command.name) {
        this.$emit('content-updated', c)
      }
    })
    // @ts-ignore
    this.editor.session.selection.on('changeCursor', () => {
      // @ts-ignore
      this.$emit('cursor-position-changed', this.editor.getCursorPosition())
    })
    // this.editor.session.selection.on('changeSelection', (a: unknown, b: {

    // }, c: unknown) => {

    // })
  }

  setReadonly (readonly: boolean) {
    console.log('setting readonly')
    // @ts-ignore
    this.editor.setOptions({
      readOnly: readonly
    })
  }

  codeUpdatedExternally (content: ChangeDelta) {
    // @ts-ignore
    if (content.action === 'insert') {
      // @ts-ignore
      this.editor.getSession().insert(content.start, content.lines.join('\n'))
    }
    if (content.action === 'remove') {
      // @ts-ignore
      this.editor.getSession().remove(new Range(
        content.start.row,
        content.start.column,
        content.end.row,
        content.end.column
      ))
    }
  }

  fullCodeUpdatedExternally (content: string) {
    this.code = content
  }

  editorInit () {
    // @ts-ignore
    require('brace/ext/language_tools')
    // @ts-ignore
    require('brace/mode/html')
    // @ts-ignore
    require('brace/mode/javascript')
    // @ts-ignore
    require('brace/mode/less')
    // @ts-ignore
    require('brace/theme/chaos')
    // @ts-ignore
    require('brace/snippets/javascript')
  }
}
