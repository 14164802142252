import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import RouterView from '../views/RouterView.vue'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: RouterView,
    children: [
      {
        path: '/:id?/:name?',
        name: 'Home',
        component: Home
      }
    ]
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
