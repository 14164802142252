var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AceEditor',{attrs:{"id":_vm.editorId,"lang":"javascript","theme":"chaos","width":"100%","height":"100%","options":{
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
      fontSize: 14,
      highlightActiveLine: true,
      enableSnippets: true,
      showLineNumbers: true,
      tabSize: 2,
      showPrintMargin: false,
      showGutter: true
  },"commands":[
    {
      name: 'save',
      bindKey: { win: 'Ctrl-s', mac: 'Command-s' },
      exec: function () {},
      readOnly: true
    }
  ]},on:{"init":_vm.editorInit},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})}
var staticRenderFns = []

export { render, staticRenderFns }