var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.nameSet)?_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticStyle:{"height":"5vh"}},[_c('b-col',{staticStyle:{"text-align":"center"}},[_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.run}},[_vm._v("Run Code")]),_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.clearConsole}},[_vm._v("Clear Console")])],1),_c('b-col',_vm._l((Object.entries(_vm.users)),function(ref){
var key = ref[0];
var value = ref[1];
return _c('div',{key:key,staticClass:"numberCircle",style:({backgroundColor: value.colour})},[_vm._v(" "+_vm._s(value.name.substr(0, 1).toUpperCase())+" ")])}),0)],1),_c('b-row',{staticStyle:{"height":"95vh"}},[_c('b-col',{attrs:{"md":"8"}},[(_vm.roomJoined)?_c('ace-editor',{ref:"editor",on:{"content-updated":_vm.contentUpdatedHandler,"updated-code":_vm.codeUpdatedFn,"cursor-position-changed":_vm.cursorChangedFn}}):_c('div',[_vm._v(" Joining... ")])],1),_c('b-col',{attrs:{"md":"4"}},[_c('div',{staticStyle:{"min-height":"40vh","max-height":"30vh","overflow-y":"auto","padding-bottom":"1vh"},attrs:{"id":"scroll-box"}},_vm._l((_vm.testRunnerOutput),function(ref,index){
var type = ref.type;
var output = ref.output;
var date = ref.date;
var name = ref.name;
return _c('span',{key:(type + "-" + index)},[_c('span',[_vm._v("["+_vm._s(new Date(date).toLocaleTimeString())+"] ")]),_c('span',[_vm._v("["+_vm._s(name)+"]")]),_c('span',{style:({color: type === 'error' ? 'red' : 'green' })},[_vm._v(" "+_vm._s(output)+" ")]),_c('br')])}),0),_c('h3',[_vm._v("Scenarios")]),_c('b-btn',{on:{"click":_vm.editScenarios}},[_vm._v("Edit")]),_c('div',{staticStyle:{"max-height":"40vh","overflow-y":"auto"}},[(!_vm.editingScenarios)?_c('div',_vm._l((_vm.scenarios),function(scenario){return _c('span',{key:scenario.key},[_vm._v(" "+_vm._s(scenario.message)+" "),_c('br')])}),0):_c('div',[_c('b-form-textarea',{attrs:{"rows":"3"},model:{value:(_vm.scenarioInput),callback:function ($$v) {_vm.scenarioInput=$$v},expression:"scenarioInput"}}),_c('b-btn',{on:{"click":_vm.saveScenarios}},[_vm._v("save")])],1)])],1)],1)],1):_c('b-container',{staticStyle:{"height":"100vh"}},[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{staticStyle:{"margin-top":"40vh"},attrs:{"align-v":"center"}},[_c('b-input-group',{staticClass:"mt-3",attrs:{"prepend":"Name","size":"lg"}},[_c('b-form-input',{model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('b-input-group',{staticClass:"mt-3",attrs:{"prepend":"Room","size":"lg"}},[_c('b-form-input',{model:{value:(_vm.id),callback:function ($$v) {_vm.id=$$v},expression:"id"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-success"},on:{"click":_vm.connectToRoom}},[_vm._v("Go")])],1)],1)],1)],1)],1),_c('b-toast',{attrs:{"id":"sync-toast","title":"Code Out Of Sync!","variant":"danger","autoHideDelay":"3000"}},[_vm._v(" Looks like more than 1 person is trying to type at the same time. We'll send a request to \""),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.syncUser.name)+"\"")]),_vm._v(" for their most recent code. Your editor will be locked temporarily. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }